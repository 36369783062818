import React from 'react';
import '../styles/Documents.css'; // Import the CSS file

const Refundandcancellation = () => {
  return (
    <div className="x1y9q-container">
      <h1 className="a7b2e-title">Refund and Cancellation Policy</h1>
      
      <div className="s3p8w-section" id="z9c4x">
        <h2 className="k2p7f-heading">1. Return and Refund Policy</h2>
        <p className="t4r9y-paragraph">
          Return is accepted only for the damaged products. If you receive a damaged product, please contact us immediately at <a href="mailto:kharthikasarees@gmail.com">kharthikasarees@gmail.com</a> with photos of the damaged item and packaging. We will initiate a refund or replacement as soon as possible, this process takes maximum 2 days.
        </p>
      </div>

      <div className="s3p8w-section" id="q8z2m">
        <h2 className="k2p7f-heading">2. Cancellation Policy</h2>
        <p className="t4r9y-paragraph">
          Cancellation requests can be made at any time before the product is shipped. Once the product is shipped, cancellation requests cannot be accommodated, cancellation can be only done by sending mail to us
          <br /><strong><u>NOTE</u> -  Once the product is shipped it can't be cancelled and we initiate the refund only for damaged products. Though we perform 4 layers of security checks before shipping the order.</strong>
          This policy is in place to prevent the misuse of our products.
        </p>
        <p className="t4r9y-paragraph">
          To cancel your order, please contact us at <a href="mailto:kharthikasarees@gmail.com">kharthikasarees@gmail.com</a> with your order details.
        </p>
      </div>

      <div className="s3p8w-section" id="w6d9j">
        <h2 className="k2p7f-heading">We appreciate your understanding of our policies as we aim to provide quality products and services.</h2>
      </div>
    </div>
  );
}

export default Refundandcancellation;
